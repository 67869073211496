import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Typography, Spin, Alert, Table } from 'antd';

const { Title, Paragraph } = Typography;

interface Customer {
  name: string;
  surname: string;
  email: string;
  identification_number: string;
}

interface TicketType {
  type: string;
  description: string;
}

interface TicketDetail {
  ticket_type: TicketType;
  quantity: number;
  current_amount: string;
}

interface TicketDetailsData {
  purchase_date: string;
  total_amount: string;
  customer: Customer;
  ticket_details: TicketDetail[];
  validation_info: null | string;
}

const TicketDetails: React.FC = () => {
  const { validationCode } = useParams<{ validationCode: string }>();
  const [ticketDetails, setTicketDetails] = useState<TicketDetailsData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchTicketDetails = async () => {
    try {
      const response = await axios.get<TicketDetailsData>(
        `${process.env.REACT_APP_API_URL}/tickets/verification/${validationCode}`
      );
      setTicketDetails(response.data);
    } catch (err) {
      setError('Error fetching ticket details.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTicketDetails();
  }, [validationCode]);

  if (loading) {
    return <Spin tip="Cargando detalles del ticket..." />;
  }

  if (error) {
    return <Alert message={error} type="error" />;
  }

  // Configuración de columnas para los detalles de los tickets
  const columns = [
    {
      title: 'Tipo de Entrada',
      dataIndex: ['ticket_type', 'type'],
      key: 'type',
    },
    {
      title: 'Descripción',
      dataIndex: ['ticket_type', 'description'],
      key: 'description',
    },
    {
      title: 'Cantidad',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (quantity: number) => `${quantity} entrada${quantity > 1 ? 's' : ''}`,
    },
    {
      title: 'Monto',
      dataIndex: 'current_amount',
      key: 'current_amount',
      render: (amount: string) => `$ ${Number(amount).toFixed(2)}`,
    },
  ];

  return (
    <div>
      <Title level={2}>Detalles del Ticket</Title>
      <Paragraph>
        <b>Fecha de Compra:</b> {ticketDetails?.purchase_date}
      </Paragraph>
      <Paragraph>
        <b>Total Pagado:</b> $ {Number(ticketDetails?.total_amount).toFixed(2)}
      </Paragraph>
      <Paragraph>
        <b>Cliente:</b> {ticketDetails?.customer.name} {ticketDetails?.customer.surname}
      </Paragraph>
      <Paragraph>
        <b>Email:</b> {ticketDetails?.customer.email}
      </Paragraph>
      <Paragraph>
        <b>DNI:</b> {ticketDetails?.customer.identification_number}
      </Paragraph>

      <Title level={4}>Detalles de las Entradas</Title>
      <Table
        columns={columns}
        dataSource={ticketDetails?.ticket_details}
        rowKey={(record) =>
          `${record.ticket_type.type}-${record.ticket_type.description}-${record.quantity}`
        }
        pagination={false}
        bordered
      />
    </div>
  );
};

export default TicketDetails;
