import React, { useState } from 'react';
import PersonalData from './PersonalData';
import styled from 'styled-components';

interface Step2Props {
    onNext: () => void;
    onPrevious: () => void;
}

const Button = styled.button`
        width: 100%;
        padding: 10px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 20px;

        @media screen and (min-width: 768px) {
            width: auto; /* Ancho automático para pantallas mayores a 768px */
            margin-top: 0; /* Elimina el margen superior para alinear con los inputs */
        }
    `;

const Step2: React.FC<Step2Props> = ({ onNext, onPrevious }) => {
    const [formValid, setFormValid] = useState(false);
    // Lógica para manejar los datos personales

    const handleNext = () => {
        // Manejar la lógica para guardar los datos personales o realizar alguna acción necesaria
        // Luego, llamar a onNext para pasar al siguiente paso
        if (!formValid) {
            // Puedes mostrar un mensaje o realizar alguna acción aquí
            return;
        }

        onNext();
    };

    const handlePrevious = () => {
        // Lógica para volver al paso anterior (paso de las entradas)
        onPrevious();
    };

    const handleFormValidityChange = (valid: boolean) => {
        setFormValid(valid); // Actualizar el estado de validez del formulario
    };

    return (
        <div>
            <h2>Ingresa tus datos personales</h2>
            <PersonalData onFormValidityChange={handleFormValidityChange} />
            <Button style={{marginRight: 10}} onClick={handlePrevious}>Anterior</Button>
            <NextButton onClick={handleNext}>Siguiente</NextButton>
        </div>
    );
};

export default Step2;

const NextButton = styled.button`
  width: 100%;
  padding: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;

  @media screen and (min-width: 768px) {
    width: 200px; /* Ancho máximo para pantallas mayores a 768px */
  }

`;