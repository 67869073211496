import React, { useState, useEffect } from "react";
import { useTicketContext } from './TicketContext';
import axios from "axios";
import { TicketType } from "../../constants/ticketType";

interface TicketSalesProps {
    onEntrySelected: (isSelected: boolean) => void; // Función de devolución de llamada para notificar la selección de entradas
}

const TicketSales: React.FC<TicketSalesProps> = ({ onEntrySelected }) => {
    const [ticketData, setTicketData] = useState<any[]>([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    const { selectedTickets, setSelectedTickets, setTicketDetails } = useTicketContext();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/tickets/types`);
                setTicketData(response.data.data);
            } catch (error) {
                console.error("Error fetching ticket data: ", error);
            }
        };

        fetchData();
    }, [apiUrl]);

    useEffect(() => {
        if (Array.isArray(ticketData)) {
            const updatedDetails: TicketType[] = ticketData.map((ticket) => {
                const selectedQuantity = selectedTickets[ticket.type];
                //const priceValue = ticket.non_resident_full_time;
                const priceValue = ticket.web_sale_price;
                

                return {
                    id: ticket.id,
                    type: ticket.type,
                    description: ticket.description,
                    quantity: selectedQuantity,
                    price: priceValue || '0',
                    total: (selectedQuantity * parseFloat(priceValue || '0')).toString(),
                };
            });

            setTicketDetails(updatedDetails);
        }
    }, [ticketData, selectedTickets, setTicketDetails]);

    function handleTicketQuantityChange(type: string, action: string) {
        const updatedTickets = { ...selectedTickets };
        updatedTickets[type] = action === 'add' ? selectedTickets[type] + 1 : selectedTickets[type] - 1;

        if (updatedTickets[type] < 0) {
            updatedTickets[type] = 0;
        }

        setSelectedTickets(updatedTickets);

        const entrySelected = Object.values(updatedTickets).some(quantity => quantity > 0);
        onEntrySelected(entrySelected);
    }

    const ticketSalesContainerStyles: React.CSSProperties = {
        display: 'flex',
        flexWrap: 'wrap',
    };

    const ticketTypeStyles: React.CSSProperties = {
        margin: '20px',
        padding: '10px',
        border: '1px solid #ccc',
        width: '300px',
    };

    const ticketQuantityStyles: React.CSSProperties = {
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
    };

    const buttonStyles: React.CSSProperties = {
        margin: '5px', // Espaciado entre los elementos
        backgroundColor: '#007bff',
        color: '#fff',
        padding: '15px', // Aumentar el tamaño de los botones
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        height: '40px'
    };

    const inputStyles: React.CSSProperties = {
        width: '40px',
        padding: '10px', // Aumentar el tamaño del input
        border: '1px solid #ccc', // Borde en color gris claro
        textAlign: 'center',
    };

    // Calcular el total de entradas
    const totalTickets = Object.values(selectedTickets).reduce((acc, cur) => acc + cur, 0);

    // Calcular el valor total  
    const totalPrice = Array.isArray(ticketData)
    ? ticketData.reduce((acc, ticket) => {
        const selectedQuantity = selectedTickets[ticket.type];
        //const priceValue = ticket.non_resident_full_time;
        const priceValue = ticket.web_sale_price;
        return acc + (selectedQuantity * parseFloat(priceValue || '0'));
    }, 0)
    : 0;

    return (
        <>
            <div>
                <p style={{fontSize:16}}><strong style={{color: 'red', textDecoration: 'underline'}}>La compra de entradas deben efectuarse con 8hs de anticipación, sin excepción.</strong> Entrada válida desde el 28 de diciembre 2024 hasta 28 de febrero 2025. No acumulable con otras promociones y/o descuentos. Los menores deben ingresar acompañados por un adulto responsable. <strong style={{color: 'red'}}>Los precios se aplican solamente a la venta online.</strong> Todas las personas de la reserva deben ingresar juntas el mismo dia, con DNI en mano.</p>
            </div>
            <div style={ticketSalesContainerStyles}>
            {Array.isArray(ticketData) && ticketData.length > 0 ? (
                ticketData.map((ticket: any) => (
                    <div style={ticketTypeStyles} key={ticket.id}>
                        <h3>{ticket.type}</h3>
                        <h4>{ticket.description}</h4>
                        <div className="ticket-prices">
                            <div>
                                {ticket.web_sale_price !== ticket.non_resident_full_time && (
                                    <>
                                        <p>
                                            Valor de la entrada: $ {ticket.web_sale_price} 
                                            <br/>
                                            <span style={{ textDecoration: 'line-through', color: 'gray' }}>
                                                 $ {ticket.non_resident_full_time}
                                            </span>                                             
                                        </p>
                                    </>
                                )}
                                {ticket.web_sale_price === ticket.non_resident_full_time && (
                                    <p>Valor de la entrada: $ {ticket.web_sale_price}</p>
                                )}
                            </div>  
                        </div>
                        <div style={ticketQuantityStyles}>
                            <button style={buttonStyles} onClick={() => handleTicketQuantityChange(ticket.type, 'subtract')}>-</button>
                            <input type="text" value={selectedTickets[ticket.type]} readOnly style={inputStyles} />
                            <button style={buttonStyles} onClick={() => handleTicketQuantityChange(ticket.type, 'add')}>+</button>
                        </div>
                    </div>
                ))
            ) : (
                <p>No se han encontrado datos de entradas.</p>
            )}
            </div>
            
            <div style={{marginTop:15}}>
                <p>Total de Entradas: {totalTickets}</p>
                <p>Valor Total: ${totalPrice}</p>
            </div>
        </>
    );
}


export default TicketSales; 