/*
import React from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import TransactionTable from './Tickets/TransactionTable';

const Dashboard = () => {
  return (
    <div style={{ display: 'flex', height: '100vh' }}>
        <Sidebar />
        <div style={{ flex: 1, padding: '20px' }}>
          <TransactionTable />
        </div>
      </div>
  );
};

export default Dashboard;
*/

import React, { useState } from 'react';
import { Layout, Menu, theme } from 'antd';
import { TagsOutlined, DollarOutlined, FileOutlined } from '@ant-design/icons';
import { Outlet, Link } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;

const Dashboard: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="logo" style={{ height: 32, margin: 16, background: 'transparent' }} />
        <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
          <Menu.Item key="1" icon={<TagsOutlined />}>
            <Link to="/dashboard">Tickets</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<DollarOutlined />}>
            <Link to="/dashboard/prices">Precios</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<FileOutlined />}>
            <Link to="/dashboard/sales">Reportes</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer }}>
        </Header>
        <Content style={{ margin: '16px' }}>
          <div style={{ padding: 24, minHeight: 360, background: colorBgContainer }}>
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Parque Tipicus ©2023 Creado por [SBCode Software Solutions]</Footer>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
