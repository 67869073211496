import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const apiUrl = process.env.REACT_APP_API_URL;

  
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  };

  const cardStyle: React.CSSProperties = {
    backgroundColor: '#ffffff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    width: '300px',
    textAlign: 'center',
  };

  const formStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
  };

  const labelStyle: React.CSSProperties = {
    marginBottom: '5px',
  };

  const inputStyle: React.CSSProperties = {
    padding: '8px',
    marginBottom: '15px',
    border: '1px solid #ccc',
    borderRadius: '3px',
  };

  let buttonStyle: React.CSSProperties = {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  };

  const buttonHoverStyle: React.CSSProperties = {
    backgroundColor: '#0056b3',
  };

  const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      // Hacer la solicitud a la API para el inicio de sesión
      const response = await axios.post(`${apiUrl}/login`, {
        email: username,
        password,
      });

      // Verificar si la autenticación fue exitosa
      if (response.data.accessToken) {
        localStorage.setItem('token', response.data.accessToken);
        // Redirigir a la página de dashboard en caso de éxito
        navigate('/dashboard');
      } else {
        // Manejar el caso de autenticación fallida (puedes mostrar un mensaje de error)
        console.error('Autenticación fallida');
      }
    } catch (error) {
      console.error('Error en la solicitud de inicio de sesión:', error);
    }
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <h2>Iniciar Sesión</h2>
        <form style={formStyle} onSubmit={handleLogin}>
          <label style={labelStyle} htmlFor="email">
            Usuario:
          </label>
          <input
            style={inputStyle}
            type="email"  
            id="email"    
            name="email"  
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <label style={labelStyle} htmlFor="password">
            Contraseña:
          </label>
          <input
            style={inputStyle}
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          <button
            type="submit" 
            style={buttonStyle}
            onMouseOver={() => (buttonStyle = { ...buttonStyle, ...buttonHoverStyle })}
            onMouseOut={() => (buttonStyle = { ...buttonStyle })}
          >
            Iniciar Sesión
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
