import React, { useState, useEffect } from 'react';
import { Table, Button, Typography, Space, Input, Select } from 'antd';
import axios from 'axios';
import { Link } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

interface Customer {
  name: string;
  surname: string;
  identification_number: string;
}

interface Transaction {
  id: number;
  purchase_date: string;
  customer: Customer;
  total_amount: number;
  transaction_number: string;
  validation_code: string;
  validation_status: number;
  validation_date?: string;
  ticket_details_count: number;
}

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const TransactionTable: React.FC = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filterStatus, setFilterStatus] = useState<number | null>(null); // null representa "TODOS"

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get<Transaction[]>(`${apiUrl}/tickets`);
      setTransactions(response.data);
      setFilteredTransactions(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let data = transactions;

    // Filtrar por texto de búsqueda
    if (searchText) {
      data = data.filter((transaction) =>
        `${transaction.customer.name} ${transaction.customer.surname}`
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );
    }

    // Filtrar por estado de validación
    if (filterStatus !== null) {
      data = data.filter((transaction) => transaction.validation_status === filterStatus);
    }

    setFilteredTransactions(data);
  }, [searchText, filterStatus, transactions]);

  const handleValidate = async (validationCode: string) => {
    try {
      await axios.post(`${apiUrl}/tickets/validate`, { code: validationCode });
      fetchData();
    } catch (error) {
      console.error('Error validating:', error);
    }
  };

  const handleCancel = async (validationCode: string) => {
    try {
      await axios.post(`${apiUrl}/tickets/cancel`, { code: validationCode });
      fetchData();
    } catch (error) {
      console.error('Error canceling:', error);
    }
  };

  const columns: ColumnsType<Transaction> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'F. de Compra',
      dataIndex: 'purchase_date',
      key: 'purchase_date',
    },
    {
      title: 'Cliente',
      key: 'customer',
      render: (_, record) => `${record.customer.name} ${record.customer.surname}`,
    },
    {
      title: 'Total',
      dataIndex: 'total_amount',
      key: 'total_amount',
      render: (value) => `$ ${Number(value).toFixed(2)}`,
    },
    {
      title: 'Nro Trans.',
      dataIndex: 'transaction_number',
      key: 'transaction_number',
    },
    {
      title: 'Código MP',
      dataIndex: 'validation_code',
      key: 'validation_code',
      render: (code) => <Link to={`/dashboard/ticket-details/${code}`}>{code}</Link>,
    },
    {
      title: 'Estado',
      dataIndex: 'validation_status',
      key: 'validation_status',
      render: (status) =>
        status === 1 ? (
          <CheckCircleTwoTone twoToneColor="green" />
        ) : (
          <CloseCircleTwoTone twoToneColor="red" />
        ),
    },
    {
      title: 'F. de Validación',
      dataIndex: 'validation_date',
      key: 'validation_date',
    },
    {
      title: 'DNI',
      dataIndex: ['customer', 'identification_number'],
      key: 'dni',
    },
    {
      title: 'Entradas',
      dataIndex: 'ticket_details_count',
      key: 'ticket_details_count',
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => handleValidate(record.validation_code)}>
            Validar
          </Button>
          <Button type="default" danger onClick={() => handleCancel(record.validation_code)}>
            Cancelar
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Title level={2}>Lista de Transacciones</Title>
      <Space style={{ marginBottom: 16, width: '100%', justifyContent: 'space-between' }}>
        <Search
          placeholder="Buscar por cliente"
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: '300px' }}
        />
        <Select
          placeholder="Filtrar por estado"
          onChange={(value) => setFilterStatus(value)}
          allowClear
          style={{ width: '200px' }}
        >
          <Option value={null}>Todos</Option>
          <Option value={1}>Validado</Option>
          <Option value={0}>No Validado</Option>
        </Select>
      </Space>
      <Table
        columns={columns}
        dataSource={filteredTransactions}
        rowKey="id"
        loading={loading}
        bordered
      />
    </div>
  );
};

export default TransactionTable;
