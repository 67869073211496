import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Home from '../Page/Home/Home';
import Prices from '../Page/Prices/Prices';
import Tickets from '../Page/Tickets/Tickets';
import Us from '../Page/Us/Us';
import Success from '../Page/Success/Success';
import Login from '../Page/Login/Login';
import Dashboard from '../Page/Dashboard/Dashboard';
import TransactionTable from '../Page/Dashboard/Tickets/TransactionTable';
import Sales from '../Page/Dashboard/Sales';
import PricesForm from '../Page/Dashboard/PricesForm';
import TicketDetails from '../Page/Dashboard/Tickets/TicketDetails';

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},
	{
		path: '/prices',
		element: <Prices />,
	},
	{
		path: '/us',
		element: <Us />,
	},
	{
		path: '/tickets',
		element: <Tickets />,
	},
	{
		path: '/success',
		element: <Success />,
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
	  path: '/dashboard',
	  element: <Dashboard />,
	  children: [
		{
		  index: true, // Ruta predeterminada para /dashboard
		  element: <TransactionTable />,
		},
		{
		  path: 'tickets',
		  element: <TransactionTable />,
		},
		{
		  path: 'sales',
		  element: <Sales />,
		},
		{
		  path: 'prices',
		  element: <PricesForm />,
		},
		{
		  path: 'ticket-details/:validationCode',
		  element: <TicketDetails />,
		},
	  ],
	},

]);
