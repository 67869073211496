import React, { useCallback, useEffect, useState } from 'react';
import Summary from './Summary';
import styled from 'styled-components';
import { initMercadoPago, CardPayment } from '@mercadopago/sdk-react'
import axios from 'axios';
import { useTicketContext } from './TicketContext';


interface Step3Props {
    onPrevious: () => void;
}

const Button = styled.button`
        width: 100%;
        padding: 10px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 20px;

        @media screen and (min-width: 768px) {
            width: auto; /* Ancho automático para pantallas mayores a 768px */
            margin-top: 0; /* Elimina el margen superior para alinear con los inputs */
        }
    `;

const Step3: React.FC<Step3Props> = ({ onPrevious }) => {
    const [preferenceId, setPreferenceId] = useState<string>('');
    const { personalData, ticketDetails } = useTicketContext();
    const filteredDetails = ticketDetails.filter((detail) => detail.quantity > 0);
    const apiUrl = process.env.REACT_APP_API_URL;
    const keyMP = process.env.MERCADO_PAGO;

    const calculateTotalPrice = () => {
        let totalPrice = 0;

        // Iterar sobre los detalles filtrados
        filteredDetails.forEach((detail) => {
            const total = parseFloat(detail.total);
            totalPrice += total;
        });

       return totalPrice;
    };

      initMercadoPago('APP_USR-d8fa6359-f4fd-44c5-b5d0-c7bcf7d1db3f',{
        locale: 'es-AR'
      });


      const fetchPreferenceId = useCallback(async () => {
        try {
            const filteredDetails = ticketDetails.filter((detail) => detail.quantity > 0);
            const requestBody = {
                personalData: personalData,
                filteredDetails: filteredDetails,
            };

            const response = await axios.post(`${apiUrl}/preference`, requestBody);
            setPreferenceId(response.data.id);
        } catch (error) {
            console.error('Error al obtener el preferenceId:', error);
            // Manejo de errores
        }
    }, [apiUrl, personalData, ticketDetails]);

    useEffect(() => {
        fetchPreferenceId();
    }, [fetchPreferenceId]);

    const initialization = {
        preferenceId: preferenceId,
        amount: calculateTotalPrice(),
       };

    const onError = async () => {
        window.location.href = '/success?payment_status=0';
    };
    
    
    const onReady = async () => {
    /*
        Callback llamado cuando Brick está listo.
        Aquí puedes ocultar cargamentos de su sitio, por ejemplo.
    */
    };

    return (
        <div >
            <h2>Resumen de la compra</h2>
            <Summary />
            <div style={{ display: 'flex', alignItems: 'center', verticalAlign: 'center', marginTop: '40px'}}>
                <CardPayment
                    initialization={initialization}
                    onSubmit={async (param) => {
                        try {
                            const tickets = ticketDetails.filter((detail) => detail.quantity > 0);

                            const updatedParam = {
                                ...param,  // Mantener la información existente en 'param'
                                personalData,
                                tickets,
                            }
                            // Realizar el llamado a la API utilizando fetch
                            const response = await fetch(`${apiUrl}/process_payment`, {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json',
                                // Puedes incluir otros encabezados según sea necesario
                              },

                              body: JSON.stringify(updatedParam),  // Asegúrate de convertir el objeto 'param' a JSON
                            });
                      
                            if (!response.ok) {
                              throw new Error(`Error al llamar a la API: ${response.statusText}`);
                            }
                      
                            // Manejar la respuesta de la API
                            const responseData = await response.json();

                            if (responseData.status === 'approved') {
                               window.location.href = '/success?payment_status=1';
                            } else {
                                window.location.href = '/success?payment_status=0';
                            }
                          } catch (error) {
                            console.error('Error en la llamada a la API:', error);
                          }
                      }}
                    onReady={onReady}
                    onError={async(error) =>{
                        console.log(error.message);
                    }}
                />
            </div>
        </div>
    );
};



export default Step3;
