import React, { useState } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import SectionLayout from '../../components/SectionLayout/SectionLayout';
import TitleSection from '../../components/TitleSection/TitleSection';

import Wizard from '../../components/Wizard/Wizard';

const Tickets = () => {
    const [currentStep, setCurrentStep] = useState(1);

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    return (
		<PageLayout>
			<SectionLayout>
			<TitleSection mainTitle='VENTA DE ENTRADAS' />
             <Wizard currentStep={currentStep} onNextStep={handleNextStep} onPreviousStep={handlePreviousStep}/>
            </SectionLayout>
        </PageLayout>
)};

export default Tickets;