import React from 'react';
import { useTicketContext } from './TicketContext';

const Summary: React.FC = () => {
    const { personalData, ticketDetails } = useTicketContext();
    const filteredDetails = ticketDetails.filter((detail) => detail.quantity > 0);

    const calculateTotalPrice = () => {
        let totalPrice = 0;

        // Iterar sobre los detalles filtrados
        filteredDetails.forEach((detail) => {
            const total = parseFloat(detail.total);
            totalPrice += total;
        });

        // Redondear el total a dos decimales
        totalPrice = parseFloat(totalPrice.toFixed(2));

        const totalAsString = totalPrice.toFixed(2);

        return totalAsString;
    };

    return (
        <div>
            <div>
                <h3>Datos Personales</h3>
                <p>
                    {personalData.surname}, {personalData.name}
                    <br />
                    {personalData.identificationNumber}
                    <br />
                    {personalData.email}
                </p>
            </div>
            <div>
        <h3>Detalles de Entradas</h3>
        <table>
          <thead>
            <tr>
              <th>Cantidad</th>
              <th>Descripción</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
          {filteredDetails.map((detail) => (
              <tr key={detail.id}>
                <td style={{ textAlign: 'center' }}>{detail.quantity}</td>
                <td>{detail.type}</td>
                <td style={{ textAlign: 'right' }}>${parseFloat(detail.total).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ marginTop: '20px' }}>
        <p>Total a Pagar ${calculateTotalPrice()}</p>
        </div>
      </div>
        </div>
    );
};

export default Summary;
