import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PricesForm = () => {
  const [ticketTypes, setTicketTypes] = useState<Ticket[]>([]);
  const [updatedTickets, setUpdatedTickets] = useState<Record<number, Partial<Ticket>>>({});
  const apiUrl = process.env.REACT_APP_API_URL;

  interface Ticket {
    id: number;
    type: string;
    description: string;
    resident_full_time: number;
    resident_part_time: number;
    non_resident_full_time: number;
    non_resident_part_time: number;
    web_sale_price: number;
  }

  useEffect(() => {
    const fetchTicketTypes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/tickets/types`);
        const data = response.data.data;

        if (!Array.isArray(data)) {
          throw new Error('La API no devolvió un array.');
        }

        // Convertir los valores numéricos que vienen como cadenas
        const processedData: Ticket[] = data.map((item: any) => ({
          id: item.id,
          type: item.type,
          description: item.description,
          resident_full_time: parseFloat(item.resident_full_time),
          resident_part_time: parseFloat(item.resident_part_time),
          non_resident_full_time: parseFloat(item.non_resident_full_time),
          non_resident_part_time: parseFloat(item.non_resident_part_time),
          web_sale_price: parseFloat(item.web_sale_price),
        }));

        setTicketTypes(processedData);
      } catch (error) {
        console.error('Error fetching ticket types:', error);
      }
    };

    fetchTicketTypes();
  }, [apiUrl]);

  const handleInputChange = (id: number, field: keyof Ticket, value: string | number) => {
    setUpdatedTickets((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: typeof value === 'string' ? parseFloat(value) || value : value,
      },
    }));
  };

  const handleUpdateAll = async () => {
    try {
      const updates = Object.entries(updatedTickets).map(([id, changes]) => ({
        id: Number(id),
        ...changes,
      }));

      await Promise.all(
        updates.map((ticket) =>
          axios.put(`${apiUrl}/tickets/types/${ticket.id}`, ticket)
        )
      );

      alert('Registros actualizados con éxito');
      setUpdatedTickets({}); // Limpia los cambios pendientes
    } catch (error) {
      console.error('Error updating ticket types:', error);
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <div style={{ flex: 1, padding: '20px' }}>
        <h2>Actualizar Precios</h2>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ccc', padding: '8px' }}>Tipo</th>
              <th style={{ border: '1px solid #ccc', padding: '8px' }}>Descripción</th>
              <th style={{ border: '1px solid #ccc', padding: '8px', background: '#e0e0e0' }}>Residente J. Completa</th>
              <th style={{ border: '1px solid #ccc', padding: '8px' }}>Residente Media J.</th>
              <th style={{ border: '1px solid #ccc', padding: '8px', background: '#e0e0e0' }}>No Residente J. Completa</th>
              <th style={{ border: '1px solid #ccc', padding: '8px' }}>No Residente Media J.</th>
              <th style={{ border: '1px solid #ccc', padding: '8px' }}>Precio Venta Web</th>
            </tr>
          </thead>
          <tbody>
            {ticketTypes.map((ticket) => (
              <tr key={ticket.id}>
                <td style={{ border: '1px solid #ccc', padding: '8px' }}>{ticket.type}</td>
                <td style={{ border: '1px solid #ccc', padding: '8px' }}>{ticket.description}</td>
                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                  <input
                    type="number"
                    defaultValue={ticket.resident_full_time}
                    onChange={(e) =>
                      handleInputChange(ticket.id, 'resident_full_time', e.target.value)
                    }
                    style={{
                      width: '100%',
                      border: '1px solid #ddd', // Borde más claro
                      padding: '5px',
                      borderRadius: '4px',
                      outline: 'none',
                      textAlign: 'right'
                    }}
                  />
                </td>
                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                  <input
                    type="number"
                    defaultValue={ticket.resident_part_time}
                    onChange={(e) =>
                      handleInputChange(ticket.id, 'resident_part_time', e.target.value)
                    }
                    style={{
                      width: '100%',
                      border: '1px solid #ddd', // Borde más claro
                      padding: '5px',
                      borderRadius: '4px',
                      outline: 'none',
                      textAlign: 'right'
                    }}
                  />
                </td>
                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                  <input
                    type="number"
                    defaultValue={ticket.non_resident_full_time}
                    onChange={(e) =>
                      handleInputChange(ticket.id, 'non_resident_full_time', e.target.value)
                    }
                    style={{
                      width: '100%',
                      border: '1px solid #ddd', // Borde más claro
                      padding: '5px',
                      borderRadius: '4px',
                      outline: 'none',
                      textAlign: 'right'
                    }}
                  />
                </td>
                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                  <input
                    type="number"
                    defaultValue={ticket.non_resident_part_time}
                    onChange={(e) =>
                      handleInputChange(ticket.id, 'non_resident_part_time', e.target.value)
                    }
                    style={{
                      width: '100%',
                      border: '1px solid #ddd', // Borde más claro
                      padding: '5px',
                      borderRadius: '4px',
                      outline: 'none',
                      textAlign: 'right'
                    }}
                  />
                </td>
                <td style={{ border: '1px solid #ccc', padding: '8px' }}>
                  <input
                    type="number"
                    defaultValue={ticket.web_sale_price}
                    onChange={(e) =>
                      handleInputChange(ticket.id, 'web_sale_price', e.target.value)
                    }
                    style={{
                      width: '100%',
                      border: '1px solid #ddd', // Borde más claro
                      padding: '5px',
                      borderRadius: '4px',
                      outline: 'none',
                      textAlign: 'right'
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button
          onClick={handleUpdateAll}
          style={{ marginTop: '20px', padding: '10px 20px', background: '#007BFF', color: 'white', border: 'none', cursor: 'pointer' }}
        >
          Guardar
        </button>
      </div>
    </div>
  );
};

export default PricesForm;
