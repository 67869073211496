import image2 from '../assets/Tipicus/tipicus-parque-acuatico-en-las-toninas-24.jpg';
import image3 from '../assets/Tipicus/tipicus-parque-acuatico-en-las-toninas-25.jpg';
import image4 from '../assets/Tipicus/tipicus-parque-acuatico-en-las-toninas-26.jpg';
import image1 from '../assets/Tipicus/tipicus-parque-acuatico-en-las-toninas-23.jpg';
import image7 from '../assets/Tipicus/tipicus-parque-acuatico-en-las-toninas-27.jpg';
import image8 from '../assets/Tipicus/tipicus-parque-acuatico-en-las-toninas-28.jpg';
import image9 from '../assets/Tipicus/tipicus-parque-acuatico-en-las-toninas-29.jpg';
import image10 from '../assets/Tipicus/tipicus-parque-acuatico-en-las-toninas-30.jpg';


interface ImageInterface {
	original: string;
	thumbnail?: string;
}

export const heroImg: ImageInterface[] = [
	{
		original: image3,
	},
	{
		original: image1,
	},
	{
		original: image10,
	},
	{
		original: image7,
	},
];
export const images: ImageInterface[] = [
	{
		original: image1,
		thumbnail: image1,
	},
	{
		original: image2,
		thumbnail: image2,
	},
	{
		original: image3,
		thumbnail: image3,
	},
	{
		original: image4,
		thumbnail: image4,
	},

	{
		original: image7,
		thumbnail: image7,
	},
	{
		original: image8,
		thumbnail: image8,
	},
	{
		original: image9,
		thumbnail: image9,
	},
	{
		original: image10,
		thumbnail: image10,
	},
];

export default images;
